<template>
  <div class="filters">
    <div class="columns is-vcentered location-table-filters mx-0">
      <div class="column location-search-input m-0 p-0 mr-4">
        <b-input
          v-model="locationSearchInput"
          custom-class="no-border"
          placeholder="Search..."
          type="search"
          icon="magnify" />
      </div>

      <div>
        <b-button
          :type="locationTableTabs === 0 ? 'is-light' : 'is-white'"
          class="mr-2"
          @click="locationTableTabs = 0">
          All
          <b-tag
            class="data-tags is-white"
            rounded>
            {{ locations.length }}
          </b-tag>
        </b-button>
        <b-button
          :type="locationTableTabs === 1 ? 'is-light' : 'is-white'"
          @click="locationTableTabs = 1">
          Selected
          <b-tag
            class="data-tags is-white"
            rounded>
            {{ locationsSelected.length }}
          </b-tag>
        </b-button>
      </div>
    </div>
    <b-table
      scrollable
      :height="570"
      :sticky-header="true"
      :data="resultsToShow"
      class="locations-table"
      hoverable
      @click="toggleLocationRow">
      <b-table-column
        field="displayName"
        width="30">
        <template #header>
          <b-tooltip append-to-body>
            <b-checkbox
              :value="selectAllLocationsValue"
              @input="selectAll" />
          </b-tooltip>
        </template>
        <template #default="props">
          <b-checkbox
            v-model="locationsSelected"
            :native-value="props.row.key"
            style="pointer-events: none" />
        </template>
      </b-table-column>
      <b-table-column
        v-slot="props"
        sortable
        label="Locations">
        {{ props.row.value }}
      </b-table-column>

      <template #empty>
        <div
          v-if="!isLoading"
          class="has-text-centered mt-6">
          <b-tag
            class="data-tags"
            rounded
            size="is-medium">
            No Locations Found 🥥
          </b-tag>
        </div>
      </template>
    </b-table>
    <div class="location-table-footer">
      {{ locationsSelected.length }} Selected
    </div>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'

  export default {
    name: 'ReportEditor',
    props: {
      value: { type: [Array, undefined,], default: undefined, },
    },
    data () {
      return {
        locations: [],
        reportId: null,
        isLoading: false,

        locationSearchInput: '',
        locationTableTabs: 0,

        nameInput: '',

        isActiveInput: false,
        isDirty: false,
      }
    },
    computed: {
      selectAllLocationsValue () {
        return (
          this.locationsSelected.length > 0 &&
          this.locationsSelected.length === this.resultsToShow.length
        )
      },

      locationTableData () {
        if (this.locationTableTabs === 0) {
          return this.locations
        }
        return this.locations.filter((row) =>
          this.locationsSelected.includes(row.key)
        )
      },

      searcher () {
        const searcher = new FuzzySearch(this.locationTableData, [
          'value',
        ])
        return searcher
      },

      resultsToShow () {
        if (this.locationSearchInput) {
          return this.searcher.search(this.locationSearchInput)
        }
        return this.locationTableData
      },

      locationsSelected: {
        get () {
          return this.value || []
        },
        set (val) {
          this.$emit('input', val)
        },
      },

    },

    async mounted () {
      await this.onLoad()
    },
    methods: {
      selectAll (value) {
        if (value) {
          this.locationsSelected = this.resultsToShow.map((item) => item.key)
        } else {
          this.locationsSelected = []
        }
      },
      async onLoad () {
        await this.getLocations()
      },
      makeInputsDirty () {
        this.isDirty = true
      },
      toggleLocationRow (row) {
        const indexOfItem = this.locationsSelected.indexOf(row.key)
        if (indexOfItem > -1) {
          this.locationsSelected.splice(indexOfItem, 1)
        } else {
          this.locationsSelected.push(row.key)
        }

        this.$emit('input', this.locationsSelected)
        this.isDirty = true
      },

      async getLocations () {
        const response = await this.$apis.pdfReports.getReportFilters('location')
        this.locations = response
      },
    },
  }
</script>

<style lang="scss" scoped>
.input-form {
  max-width: 400px;

  .file-name-formatted {
    margin-top: 5px;
    font-size: 15px;
    color: $ui-02;

    span {
      color: $ui-04;
    }
  }
}

.location-table-filters {
  padding: 8px 8px;
  border: $border-1;
  border-radius: 6px;
  margin: 0px;
}

.locations-table {
  font-size: 14px;
  border-radius: 4px;
  border: $border-1;
  margin-top: 5px;
}

.location-table-footer {
  border: $border-1;
  text-align: center;
  padding: 8px;
  font-size: 13px;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.data-tags {
  font-size: 11px !important;
  margin: 4px;
}
</style>
